import React from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  CardActions,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormDataConsumer,
  FormTab,
  ImageField,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  RefreshButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import CourseditActions from "./../actions/courseEditActions";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const CourseFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
    <TextInput label="Search by title" source="qTitle" alwaysOn />
    <TextInput label="Search by slug" source="qSlug" />
    <TextInput label="Search by School Name" source="qSchoolName" />
    <BooleanInput label="Search by featured course" source="qFeaturedFlag" />
  </Filter>
);

export const CourseList = props => (
  <List
    {...props}
    filters={<CourseFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Title" source="title" />
      <ReferenceField label="School" source="schoolId" reference="schools">
        <TextField source="name" />
      </ReferenceField>
      <DateField label="Created date" source="createdAt" locales="en-GB" />
      <TextField label="Slug" source="slug" />
    </Datagrid>
  </List>
);

export const CourseCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="category" />
      <BooleanInput />
      <NumberInput />
    </SimpleForm>
  </Create>
);

var options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/London"
};
export const CourseEdit = props => (
  <Edit {...props} actions={<CourseditActions />}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput label="Id" source="id" disabled />
        <TextInput label="Title" source="title" />
        <TextField label="Status" source="status" />
        <TextField label="Slug" source="slug" />
        <ReferenceField
          label="School link"
          source="schoolId"
          reference="schools"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanInput
          label="Show in Obby Marketplace"
          source="showInMarketplace"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <BooleanInput
                label="Hide from Google?"
                source="hidden"
                disabled={formData.showInMarketplace === false}
                format={val =>
                  formData.showInMarketplace === false ? true : val
                }
                helperText={
                  formData.showInMarketplace === false
                    ? "Only available when showing in the marketplace"
                    : undefined
                }
              />
              <BooleanInput
                label="Hide from Algolia Results?"
                source="hiddenFromResults"
                disabled={formData.showInMarketplace === false}
                format={val =>
                  formData.showInMarketplace === false ? true : val
                }
                helperText={
                  formData.showInMarketplace === false
                    ? "Only available when showing in the marketplace"
                    : undefined
                }
              />
            </>
          )}
        </FormDataConsumer>

        <BooleanInput label="Exclude from sales?" source="excludeFromSales" />
        <BooleanInput label="Is incomplete?" source="isIncomplete" />
        <BooleanInput label="Is online?" source="isOnline" />
        <BooleanInput
          label="Marked as sold out in Marketplace"
          source="isMarkedAsSoldOut"
        />
        <ReferenceArrayField
          label="Fixed tags"
          reference="tags"
          source="fixedTags"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="CustomTags" reference="tags" source="tags">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Private Widget Tags"
          reference="privateTags"
          source="privateTags"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Reviews">
        <ArrayInput source="reviews">
          <SimpleFormIterator>
            <NumberInput source="value" />
            <TextInput source="reviewKey" />
            <TextInput source="submittedFrom" />
            <TextInput source="text" />
            <DateField source="createdAt" locales="en-GB" />
            <TextInput source="userId" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Sessions">
        <ReferenceManyField
          label="Sessions"
          reference="sessions"
          target="courseID"
          pagination={<Pagination />}
          sort={{ field: "startDateTime", order: "DESC" }}
        >
          <Datagrid>
            <TextField source="id" />
            <DateField
              label="Date"
              source="startDateTime"
              locales="en-GB"
              options={options}
            />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Images">
        <ImageField source="images" src="url" title="title" />
      </FormTab>

      <FormTab label="User Inputs">
        <h1>Old structure</h1>
        <ArrayInput source="userInputs">
          <SimpleFormIterator>
            <TextInput source="label" />
            <TextInput source="description" />
          </SimpleFormIterator>
        </ArrayInput>
        <h1>New Structure</h1>
        <BooleanInput
          source="userQuestionForm.isEnabled"
          label="Is user question form enabled?"
        />
        <SelectInput
          source="userQuestionForm.mode"
          choices={[
            { id: "all", name: "all" },
            { id: "oneAtTime", name: "oneAtTime" }
          ]}
        />
        <ArrayInput source="userQuestionForm.questions">
          <SimpleFormIterator>
            <TextInput
              source="name"
              label="Name identifier - dont use spaces"
            />
            <NumberInput
              min={0}
              source="order"
              label="The order of question please auto increment"
            />
            <TextInput source="title" />
            <BooleanInput source="isRequired" />
            <SelectInput
              source="typeOfInput"
              choices={[
                { id: "shortTextInput", name: "shortTextInput" },
                { id: "longTextInput", name: "longTextInput" },
                { id: "singleSelect", name: "singleSelect" },
                { id: "fileInput", name: "fileInput" },
                { id: "numberInput", name: "numberInput" },
                { id: "booleanInput", name: "booleanInput" },
                { id: "multiSelect", name: "multiSelect" }
              ]}
            />
            <TextInput
              source="postQuestionAnsweredTarget"
              label="Targeted question that the user will go if answer this one. Leave blanket to follow the normal order"
            />
            <ArrayInput source="singleSelectOptions">
              <SimpleFormIterator>
                <TextInput source="name" />
                <TextInput
                  source="postOptionSelectedTarget"
                  label="Name of question for conditional"
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Customer Download Files">
        <BooleanInput
          source="customerDownloadFilesForm.isEnabled"
          label="Enable customer waiver section on checkout"
        />
        <BooleanInput
          source="customerDownloadFilesForm.allowUsersToUploadFiles"
          label="Enable customer waiver section on checkout"
        />
        <BooleanInput
          source="customerDownloadFilesForm.allowUsersToCommentOnUploadFiles"
          label="Enable customer waiver section on checkout"
        />
      </FormTab>
      <FormTab label="AVAILABILITY & BUNDLES">
        <ArrayField source="groupScheduleSlots" label="Group Schedule Slots">
          <Datagrid>
            <TextField source="_id"></TextField>
            <TextField source="type"></TextField>
            <TextField source="status"></TextField>
            <TextField source="weekday"></TextField>
            <NumberField source="isoWeekday"></NumberField>
            <NumberField source="startPeriod.hour"></NumberField>
            <NumberField source="startPeriod.minute"></NumberField>
            <NumberField source="endPeriod.hour"></NumberField>
            <NumberField source="endPeriod.minute"></NumberField>
            <NumberField source="bufferTime"></NumberField>
            <TextField source="bufferTimeOrder"></TextField>
            <ArrayField source="tickets">
              <Datagrid>
                <TextField source="_id"></TextField>
                <NumberField source="duration"></NumberField>
                <ReferenceArrayField
                  label="Tickets"
                  reference="ticketTypes"
                  source="ticketTypes"
                >
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </Datagrid>
            </ArrayField>
            <TextField souce="blockingStrategy"></TextField>
            <BooleanField source="hasSpecificLocations"></BooleanField>
            <ReferenceArrayField
              label="Locations"
              reference="partnerLocations"
              source="locations"
            >
              <SingleFieldList>
                <ChipField source="_id" />
              </SingleFieldList>
            </ReferenceArrayField>
            <BooleanField source="hasSpecificInstructors"></BooleanField>
            <ReferenceArrayField
              label="Instructors"
              reference="users"
              source="instructors"
            >
              <SingleFieldList>
                <ChipField source="renderEmail" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Datagrid>
        </ArrayField>
        <hr />
        <ArrayField
          source="appointmentScheduleSlots"
          label="Appointment Schedule Slots"
        >
          <Datagrid>
            <TextField source="_id"></TextField>
            <TextField source="type"></TextField>
            <TextField source="status"></TextField>
            <TextField source="weekday"></TextField>
            <NumberField source="isoWeekday"></NumberField>
            <NumberField source="startPeriod.hour"></NumberField>
            <NumberField source="startPeriod.minute"></NumberField>
            <NumberField source="endPeriod.hour"></NumberField>
            <NumberField source="endPeriod.minute"></NumberField>
            <NumberField source="bufferTime"></NumberField>
            <TextField source="bufferTimeOrder"></TextField>
            <ArrayField source="tickets">
              <Datagrid>
                <TextField source="_id"></TextField>
                <NumberField source="duration"></NumberField>
                <ReferenceArrayField
                  label="Tickets"
                  reference="ticketTypes"
                  source="ticketTypes"
                >
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </Datagrid>
            </ArrayField>
            <TextField souce="blockingStrategy"></TextField>
            <BooleanField source="hasSpecificLocations"></BooleanField>
            <ReferenceArrayField
              label="Locations"
              reference="partnerLocations"
              source="locations"
            >
              <SingleFieldList>
                <ChipField source="_id" />
              </SingleFieldList>
            </ReferenceArrayField>
            <BooleanField source="hasSpecificInstructors"></BooleanField>
            <ReferenceArrayField
              label="Instructors"
              reference="users"
              source="instructors"
            >
              <SingleFieldList>
                <ChipField source="renderEmail" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Datagrid>
        </ArrayField>
        <hr />
        <ArrayInput source="blockBooking">
          <SimpleFormIterator>
            <NumberInput source="sessions" />
            <NumberInput source="price" />
            <NumberInput source="studentPrice" />
            <NumberInput source="childPrice" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Zoom Details">
        <BooleanInput
          source="isOnline"
          label="Is this course marked as online?"
        />
        <TextInput source="teacherOnlineImage" label="Online image" />
        <BooleanInput
          source="requiresSendingPhysicalMaterial"
          label="Does this course requires sending physical material?"
        />
      </FormTab>
      <FormTab label="Skills & Material">
        <ArrayInput source="acquiredSkills" label="Acquired Skills">
          <SimpleFormIterator>
            <TextInput label=" " />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="requiredMaterial" label="Required Material">
          <SimpleFormIterator>
            <TextInput label=" " />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Covid Safety Guidelines">
        <BooleanInput
          label="Has accepted the Covid safety Guidelines"
          source="covidAcceptDeclaration"
        />
      </FormTab>
      <FormTab label="Sorting/Flags">
        <NumberInput source="slotOrder" />
        <BooleanInput source="advanced" />
        <BooleanInput source="featured" />
        <BooleanInput source="comingSoon" />
      </FormTab>
      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
      <FormTab label="Miscellaneous">
        <TextInput source="type" />
        <TextInput source="category" />
        <TextInput source="subcategory" />
        <TextInput source="level" />
        <TextInput source="similar" />
        <NumberInput source="datesAvailable" />
        <TextInput source="shortDescription" />
        <TextInput multiline fullWidth source="description" />
        <NumberInput source="requirements" />
        <TextInput multiline fullWidth source="classNotes" />
        <BooleanInput source="advanced" />
        <BooleanInput source="featured" />
        <BooleanInput source="comingSoon" />
      </FormTab>
    </TabbedForm>
  </Edit>
);
